import React from 'react';
import{NavigationDots, SocialMedia} from '../components';
const AppWrap = (Component, idName,classnames) => function HOC(){
  return (
    <div id={idName} className={`app__container ${classnames}`}>
        <SocialMedia/>

    <div className="app__wrapper app__flex">
        <Component/>
        <div className="copyright">
        <p className="p-text">Copyright ©2024 Développé par E-life TECHNOLOGIES</p>
        <p className="bold-text">All rights reserved</p>
    </div>
    </div>
    <NavigationDots active={idName}/>
    </div>
  )
}

export default AppWrap