import React from 'react';
import {motion} from 'framer-motion';
import {AppWrap} from '../../wrapper';
import {images} from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }

  }
}

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
      whileInView={{ x: [-100, 0], opacity: [0,1]}}
      transition={{duration: 0.5}}
      className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20}}>
              <p className="p-text">Hello, Nous sommes </p>
              <h1 className='heat-text'>E-life technologie</h1>
            </div>
          </div>
            <div className="tag-cmp app__flex">
                <h1 className="p-text">Une entreprise spécialisée dans le domaine de la nouvelle technologie de
l’informationet de communication fournissant des solutions uniques aux entreprises pour leur activité grâce à la gestion des leads et du marketing digital</h1>`
              </div>       
        </div>
      </motion.div>
      <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1]}}
      transition={{duration: 0.5}}
      className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg"/>
        <motion.img
        whileInView={{ scale: [0, 1]}}
        transition={{duration: 1, ease: 'easeInOut'}}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
        >
        </motion.img>
      </motion.div>

      <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
      >
       {[images.flutter, images.redux, images.sass].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-$(index)`}>
          <img src={circle} alt= "circle" />

        </div>
       ))} 
      </motion.div>

    </div>
  )
}

export default AppWrap(Header, 'Acceuil');